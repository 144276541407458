<template>
    <div class="col-3 col-xs-6 col-md-3">
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="12" lg="12" md="12" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTable
                        :has-add-function="true"
                        :cols-to-delete="colsToDelete"
                        class="mt-0 mr-0"
                        file-name="etdAdjustments"
                        table-id="etdAdjustmentsTable"
                        style="padding-top: 2px !important;"
                        @add-action="openCreateEtdAdjustmentDialog()"
                    ></ExportTable>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.etdAdjustments"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :items="allEtdAdjustments"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'EtdAdjustment.Country.name'"
            calculate-widths
            class="mt-0 appic-table-light specification-table"
            dense
            fixed-header
            id="etdAdjustmentsTable"
            item-key="EtdAdjustment.id"
        >
            <template v-slot:item.index="{ item }">
                <div class="text-center">{{ allEtdAdjustments.indexOf(item) + 1}}</div>
            </template>
            <template v-slot:item.EtdAdjustment.Country.name="{ item }">
                <span class="font-weight-bold">{{ item.EtdAdjustment.Country.name }}</span>
            </template>
            <template v-slot:item.EtdAdjustment.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditEtdAdjustmentDialog(item.EtdAdjustment.id, item.EtdAdjustment.Country.name)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateEtdAdjustment') }}
                            </v-list-item>
                            <v-list-item class="font-sm" @click="deleteEtdAdjustment(item.EtdAdjustment.id, item.EtdAdjustment.Country.name)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteEtdAdjustment') }}</v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-data-table>
        <AddEtdAdjustment
            :etd-adjustment-id="selectedEtdAdjustmentId"
            :etd-adjustment="selectedEtdAdjustmentOrigin"
            :dialog.sync="editDialog"
            :update-mode="updateMode"
            @dialog-closed="editDialogClosed"
            @update-done="etdAdjustmentUpdated"
            @create-done="etdAdjustmentCreated"
        ></AddEtdAdjustment>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions} from 'vuex'

const AddEtdAdjustment = () => import("Components/Appic/AddEtdAdjustment");
const ExportTable = () => import("Components/Appic/ExportTable");

export default {
    name: "EtdAdjustmentsListing",
    components: { AddEtdAdjustment, ExportTable },
    props: ['addDialog','reload'],
    data() {
        return {
            colsToDelete: {
                start_col: 0,
                ncols: 1
            },
            dialogs: {
                error: false,
                error_message: ""
            },
            editDialog: null,
            hideDefaultFooter: true,
            loading: {
                fetch: false,
                filterResults: false,
                etdAdjustments: false
            },
            searchField: null,
            searchTerm: null,
            selectedEtdAdjustmentId: null,
            selectedEtdAdjustmentOrigin: null,
            tableHeight: '500',
            tableOptions: {
                page: 1
            },
            updateMode: false
        }
    },
    computed: {
        ...mapFields('etdadjustment',{
            allEtdAdjustments: 'allEtdAdjustments'
        }),
        headers () {
            return [
                {
                    id: 1,
                    text: this.$t('message.actions'),
                    value: 'EtdAdjustment.id',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.origin'),
                    value: 'EtdAdjustment.Country.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.adjustmentDays'),
                    value: 'EtdAdjustment.days',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
            ]
        }
    },
    methods: {
        ...mapActions('etdadjustment',{
            deleteEtdAdjustmentById: 'deleteEtdAdjustmentById',
            getAllEtdAdjustments: 'getAllEtdAdjustments',
            resetCurrentState: 'resetCurrentState',
            resetState: 'resetState'
        }),
        async deleteEtdAdjustment ( val, title ) {
            if(await this.$root.$confirm(this.$t('message.deleteEtdAdjustment') + ': ' +  title, this.$t('message.confirmations.continueEtdAdjustmentCancelAction'), {color: 'orange'})){
                this.deleteEtdAdjustmentById(val)
                    .then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.etdAdjustmentDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.resetState()
                            this.getAllEtdAdjustments()
                        } else {
                            this.$toast.error(this.$t('message.errors.etdAdjustmentNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('message.errors.etdAdjustmentNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        editDialogClosed () {
            this.editDialog = false;
            if(this.updateMode == false){
                this.$emit('add-dialog-closed')
            }
        },
        etdAdjustmentCreated () {
            this.editDialog = false;
            this.resetState()
            this.resetCurrentState()
            this.$emit('reload')
        },
        etdAdjustmentUpdated () {
            this.editDialog = false;
            this.resetState()
            this.$emit('reload')
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (285);
        },
        openCreateEtdAdjustmentDialog () {
            this.updateMode = false
            this.editDialog = true
        },
        openEditEtdAdjustmentDialog (etdAdjustmentId, etdAdjustmentOrigin) {
            this.selectedEtdAdjustmentId = etdAdjustmentId
            this.selectedEtdAdjustmentOrigin = etdAdjustmentOrigin
            this.updateMode = true
            this.editDialog = true
        }
    },
    watch: {
        addDialog(value) {
            if(value) {
                this.openCreateEtdAdjustmentDialog()
            }
        }
    },
    created() {
        this.resetState()
        if(this.allEtdAdjustments.length == 0) this.getAllEtdAdjustments()
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>